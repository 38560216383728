import React, { useContext } from 'react'
import clsx from 'clsx'
import { v4 as uuid } from 'uuid'
import GenericBanner from '@components/Cms/CmsComponents-CSS/GenericBanner'
import {
  ContextWrapperData,
  PlacementContext,
} from '@components/Cms/CmsComponents-CSS/PlacementContextWrapper/PlacementContextWrapper'
import { PlacementContextType } from '../PlacementContextWrapper/types/PlacementContextWrapper.types'
import { CmsProductTile } from '@components/Cms/CmsComponents-CSS/CmsProductTile'
import { getItemsWithProductData } from '@utils/placements'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { IPlacement, IPlacementItem } from '@typesApp/cmsPlacement/Placement'
import styles from './styles/index.module.scss'
import productTileOverrideStyles from './styles/productTileOverride.module.scss'
import { ProductContextProvider } from '@components/PagesSeo/product/context/ProductContext'

export const getExpectedProductCount = (viewType: IViewType) => {
  return viewType === 'box-and-2-products' ? 2 : viewType === 'box-and-4-products' ? 4 : 0
}

const BoxAndProducts: React.FC = () => {
  const context = useContext<ContextWrapperData>(PlacementContext)
  const { data } = context as ContextWrapperData<PlacementContextType>
  const { placement } = data ?? {} as PlacementContextType
  const { viewtype: viewType } = placement ?? {} as IPlacement<IPlacementItem>
  const expectedProductCount = getExpectedProductCount(viewType)
  const products = getItemsWithProductData(placement)
  const teaser = placement?.items[0] as ILXTeaser

  const crop = viewType === 'box-and-2-products' ? 'BOX_WITH_2_PRODUCTS' : 'BOX_WITH_4_PRODUCTS'

  const displayedProducts = products.slice(0, expectedProductCount)
  const reflect = !!placement?.placementReflect ?? false
  const referenceTextStyle = !!teaser?.teaserText2 ? teaser?.teaserOverlay2Style : teaser?.teaserOverlay1Style

  return (
    <div
      className={clsx(styles[`box-with-products-${viewType}`], {
        [styles['box-with-products-reflect']]: reflect,
      })}
    >
      <div className={styles.banner}>
        <GenericBanner
          className="boxAndProducts"
          crop={crop}
          hideTermsAndConditions
          textModuleClassName={clsx(styles.textModuleBoxAndProducts, referenceTextStyle)}
        />
      </div>

      <div className={clsx(styles['products'])}>
        {displayedProducts.map(product => {
          return (
            <ProductContextProvider productData={{ product }} key={`${viewType}-item-${uuid()}`}>
              <div className={styles['item']}>
                <div className={styles.productTileWrapper}>
                  <CmsProductTile styleOverrides={productTileOverrideStyles} />
                </div>
              </div>
            </ProductContextProvider>
          )
        })}
      </div>
    </div>
  )
}

export default BoxAndProducts
